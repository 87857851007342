// @ts-nocheck

import { useEffect, useState, useRef } from 'react';
import { Box, Switch } from '@mui/material';
import { XGTextarea, XGButton, XGInput } from '@xg-cl/xg-cl';
import { useTranslation } from 'react-i18next';
import { PlusCircle, Trash } from '@phosphor-icons/react';
import {
  createTripAmenity,
  deleteTripAmenity,
  getTripAmenity,
  updateTripAmenity
} from '../../../services/trip/trip';
import * as PropTypes from 'prop-types';
import './Amenities.css';
import AddAmenityRow from './AddAmenityRow';
import { ErrorToast } from '../../../utils/alerts';
import useEscapeKey from '../../../hooks/useEscapeKey';
import useClickOutside from '../../../hooks/useClickOutside';

/**
 * Functional React component for rendering a trip additional content component
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {string} [props.id] - The ID for the trip amenities component
 * @param {...any} props.rest - Additional props to be spread on the element
 *
 * @returns {JSX.Element} React element representing the trip additional content component
 */

const Amenities = ({ tripid }) => {
  const debounceTime = 1000; // 1 second
  const timeoutIdRef = useRef(null);
  const nameRef = useRef(null);
  const { t } = useTranslation(['trip', 'common', 'errors']);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isAddAmenityFormVisible, setIsAddAmenityFormVisible] = useState(false);
  const [editingAmenityId, setEditingAmenityId] = useState(-1);
  const [isEditingName, setIsEditingName] = useState(false);

  const handleAddAmenityClick = () => {
    setIsAddAmenityFormVisible(true);
  };

  const handleDeleteAmenityClick = (tripamenityid) => async () => {
    try {
      await deleteTripAmenity(tripamenityid);
      setAmenitiesList((prev) =>
        prev.filter((amenity) => amenity.tripamenityid !== tripamenityid)
      );
    } catch (error) {
      ErrorToast(
        t('errors:amenityCouldNotBeDeleted', { reason: error.message })
      );
    }
  };

  const handleSwitchOnChange = async (event, amenityid, tripamenityid) => {
    const { name, checked } = event.target;

    const currentAmenities = [...amenitiesList];

    const amenity = currentAmenities.find((item) => {
      if (!item?.amenityid) {
        return item.tripamenityid === tripamenityid;
      } else {
        return item.amenityid === amenityid;
      }
    });

    let amenityUpdated;

    if (checked) {
      if (name === 'include' && amenity.exclude === false) {
        const result = await createTripAmenity({
          tripid,
          amenityid,
          name: amenity.name,
          description: amenity.description,
          isincluded: true
        });
        if (result) {
          amenityUpdated = {
            ...amenity,
            tripamenityid: result.data.tripamenityid,
            include: true
          };
        }
      } else if (name === 'exclude' && amenity.include === false) {
        const result = await createTripAmenity({
          tripid,
          amenityid,
          name: amenity.name,
          description: amenity.description,
          isincluded: false
        });

        if (result) {
          amenityUpdated = {
            ...amenity,
            tripamenityid: result.data.tripamenityid,
            exclude: true
          };
        }
      } else if (name === 'include') {
        await updateTripAmenity(tripamenityid, { isincluded: true });
        amenityUpdated = { ...amenity, include: true, exclude: false };
      } else {
        await updateTripAmenity(tripamenityid, { isincluded: false });
        amenityUpdated = { ...amenity, include: false, exclude: true };
      }
    } else {
      if (name === 'include' && amenity.exclude === false) {
        amenityUpdated = { ...amenity, include: false };
      } else if (name === 'exclude' && amenity.include === false) {
        amenityUpdated = { ...amenity, exclude: false };
      }
      await deleteTripAmenity(tripamenityid);

      amenityUpdated = {
        ...amenity,
        tripamenityid: null,
        include: false,
        exclude: false,
        description: amenity?.description ?? null,
        tripAmenityDescription: null
      };
    }

    const indexToUpdate = amenitiesList.findIndex((item) => {
      if (!item?.amenityid) {
        return item.tripamenityid === tripamenityid;
      } else {
        return item.amenityid === amenityid;
      }
    });

    if (indexToUpdate !== -1) {
      const updatedAmenitiesList = [
        ...currentAmenities.slice(0, indexToUpdate),
        amenityUpdated,
        ...currentAmenities.slice(indexToUpdate + 1)
      ];

      setAmenitiesList(updatedAmenitiesList);
    }
  };
  /**
   * Handles changes to the amenity name field.
   *
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} e - The change event.
   */
  const handleNameChange = (e) => {
    const { name, value } = e.target;

    setAmenitiesList((prevAmenitiesList) =>
      prevAmenitiesList.map((amenity) =>
        amenity.tripamenityid === editingAmenityId
          ? { ...amenity, [name]: value }
          : amenity
      )
    );

    setShouldUpdate(true);

    // Clear any previous timeout if the user is still typing
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      updateTripAmenity(editingAmenityId, { [name]: value });
      setShouldUpdate(false);
      setIsEditingName(false);
      setEditingAmenityId(-1);

      timeoutIdRef.current = null;
    }, debounceTime);
  };

  const onChangeTripAmenityDescription = (event, tripamenityid) => {
    const { value } = event.target;

    setAmenitiesList((prevAmenitiesList) =>
      prevAmenitiesList.map((amenity) =>
        amenity.tripamenityid === tripamenityid
          ? { ...amenity, tripAmenityDescription: value }
          : amenity
      )
    );

    setShouldUpdate(true);

    // Clear any previous timeout if the user is still typing
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      updateTripAmenity(tripamenityid, { description: value });
      setShouldUpdate(false);

      timeoutIdRef.current = null;
    }, debounceTime);
  };

  const onBlurTripAmenityDescription = (event, tripamenityid) => {
    const { value } = event.target;

    if (shouldUpdate) {
      updateTripAmenity(tripamenityid, { description: value });
      setShouldUpdate(false);
    }
  };

  const isTitleEditable = (amenity) =>
    !amenity.amenityid &&
    isEditingName &&
    amenity.tripamenityid === editingAmenityId;

  const handleTitleClick = (amenity) => () => {
    if (!amenity.amenityid) {
      setIsEditingName(true);
      setEditingAmenityId(amenity.tripamenityid);
    }
  };

  useEffect(() => {
    const fetchTripAmenities = async () => {
      const tripAmenities = await getTripAmenity(tripid);
      const list = (tripAmenities?.data ?? []).sort(
        (a, b) => a?.amenityid - b?.amenityid
      );
      setAmenitiesList(list);
    };

    fetchTripAmenities();
  }, [tripid]);

  // Disables editing state if user hits escape key
  useEscapeKey(() => setEditingAmenityId(-1));

  // Disables editing state if user clicks outside
  useClickOutside([nameRef], () => setEditingAmenityId(-1));

  return (
    <Box className='amenities-box-container'>
      <Box>
        <XGButton
          autoFocus
          id='button-add-amenity'
          text={t('addAmenity')}
          type='secondary'
          size='large'
          variant='contained'
          onClick={handleAddAmenityClick}
          startIcon={<PlusCircle size={20} />}
        />
      </Box>
      <Box>
        <table className='amenities-table'>
          <thead>
            <tr>
              <th style={{ width: '250px' }}>{t('amenity')}</th>
              <th style={{ width: '500px' }}>{t('description')}</th>
              <th style={{ width: '105px' }}>{t('included')}</th>
              <th style={{ width: '105px' }}>{t('excluded')}</th>
              <th style={{ width: '40px' }}></th>
            </tr>
          </thead>
          <tbody>
            <AddAmenityRow
              tripid={tripid}
              toggleVisibility={setIsAddAmenityFormVisible}
              isVisible={isAddAmenityFormVisible}
              setAmenitiesList={setAmenitiesList}
            />
            {amenitiesList &&
              amenitiesList.map((amenity, index) => (
                <tr key={index}>
                  <td>
                    {isTitleEditable(amenity) ? (
                      <XGInput
                        id={`amenity-name-${index}`}
                        name='name'
                        placeholder={`${t('common:enter')} title`}
                        value={amenity.name}
                        onChange={handleNameChange}
                        className={'amenities-add-amenity-form__name'}
                        autoFocus
                        type='text'
                        inputProps={{
                          maxLength: 100
                        }}
                      />
                    ) : (
                      <span onClick={handleTitleClick(amenity)} ref={nameRef}>
                        {amenity.name}
                      </span>
                    )}
                  </td>
                  <td>
                    <div className='amenities-description__row '>
                      <div className='amenities-description__row-input'>
                        <XGTextarea
                          placeholder={`${t('common:enter')} description `}
                          id={`amenity-description-${index}`}
                          type='text'
                          name='description'
                          value={
                            amenity.tripAmenityDescription ||
                            amenity.description ||
                            ''
                          }
                          onChange={(evt) =>
                            onChangeTripAmenityDescription(
                              evt,
                              amenity.tripamenityid
                            )
                          }
                          onBlur={(evt) =>
                            onBlurTripAmenityDescription(
                              evt,
                              amenity.tripamenityid
                            )
                          }
                          inputProps={{
                            maxLength: 200
                          }}
                          minRows={2}
                          maxRows={2}
                          width={'100%'}
                          disabled={!amenity.exclude && !amenity.include}
                        />
                      </div>
                      <div className='amenities-description__row-label'>
                        <span className='text-sm-medium'>
                          {amenity.tripAmenityDescription?.length ||
                            amenity.description?.length ||
                            0}
                          /200
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Switch
                      checked={amenity.include}
                      onChange={(event) =>
                        handleSwitchOnChange(
                          event,
                          amenity.amenityid,
                          amenity.tripamenityid
                        )
                      }
                      color='primary'
                      name='include'
                    />
                  </td>
                  <td>
                    <Switch
                      checked={amenity.exclude}
                      onChange={(event) =>
                        handleSwitchOnChange(
                          event,
                          amenity.amenityid,
                          amenity.tripamenityid
                        )
                      }
                      color='primary'
                      name='exclude'
                    />
                  </td>
                  <td>
                    {!amenity.amenityid && (
                      <XGButton
                        autoFocus
                        id='button-delete-amenity'
                        type='secondary'
                        size='large'
                        variant='contained'
                        onClick={handleDeleteAmenityClick(
                          amenity.tripamenityid
                        )}
                        startIcon={
                          <Trash style={{ margin: '0 auto' }} size={20} />
                        }
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

Amenities.propTypes = {
  tripid: PropTypes.number
};

export default Amenities;
