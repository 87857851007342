import { useEffect } from 'react';

/**
 * Custom hook to handle clicks outside specified elements.
 *
 * @param {Array<React.RefObject<HTMLElement>>} refs - Array of element refs to detect clicks inside.
 * @param {Function} onClickOutside - Callback function to execute on outside click.
 */
const useClickOutside = (refs, onClickOutside) => {
  useEffect(() => {
    /**
     * Handles the mousedown event.
     *
     * @param {MouseEvent} event - The mouse event.
     */
    const handleClickOutside = (event) => {
      const dialog = document.querySelector('[role="dialog"]');
      const presentation = document.querySelector(
        '[role="presentation"]:not(iframe)'
      );

      const clickedInside = refs.some(
        (ref) =>
          ref.current &&
          ref.current.contains(
            event.target instanceof Node ? event.target : null
          )
      );

      if (!clickedInside && !dialog && !presentation) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, onClickOutside]);
};

export default useClickOutside;
