//@ts-nocheck
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { formatISO, differenceInDays, addDays, subDays } from 'date-fns';
import { Box } from '@mui/material';
import { Flag } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { createTrip } from '../../../services/trip/trip';
import { useNavigate } from 'react-router-dom';
import { ErrorToast } from '../../../utils/alerts';
import { blue } from '@mui/material/colors';
import {
  XGButton,
  XGModal,
  XGInput,
  XGAutocomplete,
  XGTextarea,
  MultiDateRangePicker
} from '@xg-cl/xg-cl';
import * as PropTypes from 'prop-types';
import './CreateTrip.css';

/**
 * Functional React component for rendering a custom modal content to create a trip.
 *
 * @namespace Components
 *
 * @param {Object} props - The component's properties
 * @param {Array} [props.destinations] - Array with destinations objects.
 * @param {string} [props.id] - The ID for the trip create trip component.
 * @param {...any} props.rest - Additional props to be spread on the Box element.
 *
 * @returns {JSX.Element} React element representing the trip creating modal.
 */

const CreateTrip = ({ destinations, id, openModal, setOpenModal, ...rest }) => {
  const initialFormState = {
    title: '',
    dateRange: [null, null],
    duration: '1',
    destination: null,
    description: '',
    outline: ''
  };
  const navigate = useNavigate();
  const { t } = useTranslation(['trip', 'common']);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [formError, setFormError] = useState({
    title: '',
    duration: '',
    destination: ''
  });
  const [progress, setProgress] = useState(0);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  useEffect(() => {
    let interval;
    if (submitPressed && formData.outline && progress < 100 && !isCancelled) {
      interval = setInterval(() => {
        setProgress((prevProgress) => Math.min(prevProgress + 1, 100));
      }, 200); // Frequent updates with small increments
    }

    return () => clearInterval(interval);
  }, [submitPressed, formData.outline, progress, isCancelled]);

  const handleOnInputNumber = (evt) => {
    const { name, tagName } = evt.target;

    if (tagName.toLowerCase() === 'input') {
      evt.target.value = evt.target.value.replace(/[^0-9]/g, '');
      const duration = Number(evt.target.value);

      setFormData((prevData) => ({ ...prevData, [name]: evt.target.value }));

      const { dateRange } = formData;

      if (
        dateRange &&
        dateRange.length == 2 &&
        dateRange[0] &&
        dateRange[1] &&
        duration
      ) {
        const daysDifference = differenceInDays(dateRange[1], dateRange[0]);

        //New duration is greater than date range days, proced to add days
        if (duration > daysDifference) {
          const newEndDate = addDays(
            dateRange[1],
            duration - daysDifference - 1
          );
          setFormData((prevData) => ({
            ...prevData,
            dateRange: [dateRange[0], newEndDate]
          }));
        }

        //New duration is less than date range days, proced to remove days
        if (daysDifference > duration) {
          const newEndDate = subDays(
            dateRange[1],
            daysDifference - duration + 1
          );

          setFormData((prevData) => ({
            ...prevData,
            dateRange: [dateRange[0], newEndDate]
          }));
        }

        //New duration is equal to 1, proced to set equal dates
        if (duration === 1) {
          setFormData((prevData) => ({
            ...prevData,
            dateRange: [dateRange[0], dateRange[0]]
          }));
        }
      }
    }
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleOnDateRangeChange = (selectedDateRange = [null, null]) => {
    setFormData((prevData) => ({
      ...prevData,
      dateRange: selectedDateRange
    }));

    if (
      selectedDateRange?.length == 2 &&
      selectedDateRange[0] &&
      selectedDateRange[1]
    ) {
      const daysDifference = differenceInDays(
        selectedDateRange[1],
        selectedDateRange[0]
      );

      setFormData((prevData) => ({
        ...prevData,
        duration: `${daysDifference + 1}`
      }));
    }
  };

  const validateForm = () => {
    setFormError({
      title: '',
      duration: '',
      destination: ''
    });

    if (!formData.title || !formData.title.trim()) {
      setFormError((prevData) => ({
        ...prevData,
        title: t('pleaseAddTripTitle')
      }));
      return;
    }

    if (!formData.duration || !formData.duration.trim()) {
      setFormError((prevData) => ({
        ...prevData,
        duration: t('pleaseAddTripDuration')
      }));
      return;
    }

    if (
      isNaN(formData.duration) ||
      !Number.isInteger(Number(formData.duration)) ||
      formData.duration < 1
    ) {
      setFormError((prevData) => ({
        ...prevData,
        duration: t('pleaseAddValidTripDuration')
      }));
      return;
    }

    if (formData.outline && !formData.destination) {
      setFormError((prevData) => ({
        ...prevData,
        destination: t('pleaseAddDestination')
      }));
      return;
    }

    return true;
  };

  const handleConfirm = async (evt) => {
    evt.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setSubmitPressed(true);
    setIsCancelled(false);

    try {
      const payload = {
        ...formData,
        name: formData.title,
        startDate:
          formData.dateRange &&
          formData.dateRange.length &&
          formData.dateRange[0]
            ? formatISO(formData.dateRange[0])
            : null,
        duration: Number(formData.duration),
        description: '',
        outline: formData.outline
      };

      const result = await createTrip(payload);

      if (result.error || isCancelled) {
        ErrorToast(t('tripNotCreatedTryAgain'));
        setLoading(false);
        setProgress(0); // Reset progress
        setSubmitPressed(false); // Reset submit pressed state
        return;
      }

      if (result.data && result.data.tripId) {
        navigate(`/trips/${result.data.tripId}`);
      }
    } catch (err) {
      if (!isCancelled) {
        ErrorToast(t('tripNotCreatedTryAgain'));
      }
    }

    setLoading(false);
    setProgress(0); // Reset progress
    setSubmitPressed(false); // Reset submit pressed state
  };

  const handleCancel = () => {
    setIsCancelled(true);
    setLoading(false);
    setSubmitPressed(false);
    setProgress(0);
    setFormData(initialFormState);
    setOpenModal(false);
  };

  const ModalActions = () => (
    <>
      <XGButton
        id='trip-modal-cancel-button'
        text={t('cancel', { ns: 'common' })}
        type='secondary'
        size='large'
        variant='outlined'
        onClick={handleCancel}
      />
      <Box sx={{ m: 1, position: 'relative', width: '50%' }}>
        <XGButton
          style={{ width: '100%' }}
          id='trip-modal-continue-button'
          text={t('letsStart')}
          type='primary'
          size='large'
          variant='contained'
          onClick={handleConfirm}
          disabled={loading}
        />
        {loading && !formData.outline && (
          <CircularProgress
            size={24}
            sx={{
              color: blue[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        )}
      </Box>
    </>
  );

  return (
    <Box id={id} data-testid='create-trip-view' {...rest}>
      <XGModal
        actions={<ModalActions />}
        allowClosing={false}
        dismissable={false}
        id='create-trip-modal'
        open={openModal}
        title={t('createTrip')}
        labelledby={t('createTrip')}
        maxWidth='lg'
        scroll='body'
      >
        <Box className='create-trip-header'>
          <Flag size={20} /> <p>{t('tripInformation')}</p>
        </Box>

        <Box className='create-trip-content'>
          <Box className='create-trip-row'>
            <XGInput
              error={formError.title !== ''}
              hint={formError.title}
              label={`${t('title')}*`}
              name='title'
              placeholder={t('enterTitle')}
              id='trip-title-input'
              type='text'
              value={formData.title}
              onChange={handleChange}
              inputProps={{
                maxLength: 250
              }}
            />
            <span className='title-length-hint length-hint text-sm-medium'>
              {formData.title.length}/250
            </span>
          </Box>

          <Box className='create-trip-date'>
            <Box style={{ width: '70%' }}>
              <p className='MuiTypography-root MuiTypography-body2 create-trip__date-label'>
                <span className='w-50'>{t('startDate')} </span>
                <span className='w-50' style={{ marginLeft: '40px' }}>
                  {t('endDate')}
                </span>
              </p>
              <MultiDateRangePicker
                customClassName='nooverflow'
                format={`${t('common:dateFormat')}`}
                id='trip-start-date-input'
                name='dateRange'
                value={formData.dateRange}
                onChange={(selectedDate) => {
                  handleOnDateRangeChange(selectedDate);
                }}
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  }
                }}
                minDate={new Date()}
              />
            </Box>

            <Box className='duration-container'>
              <XGInput
                error={formError.duration !== ''}
                hint={formError.duration}
                type='text'
                value={formData.duration}
                label={`${t('duration')}*`}
                name='duration'
                id='trip-duration-input'
                onInput={handleOnInputNumber}
                inputProps={{
                  maxLength: 3
                }}
              />

              <span
                className={`text-md-regular duration-placeholder ${
                  formError.duration && 'duration-placeholder-error'
                }`}
              >
                {t('days').toLowerCase()}
              </span>
            </Box>
          </Box>
          <div>
            <XGAutocomplete
              placeholder={t('searchByCountryOrCity')}
              name='destination'
              label={`${t('destination')}${formData.outline ? '*' : ''}`}
              id='trip-destination-input'
              options={destinations}
              isOptionEqualToValue={(option, value) =>
                option.title === value?.title
              }
              onChange={(event, newValue) => {
                setFormData((prevData) => ({
                  ...prevData,
                  destination: newValue
                }));
                if (newValue) {
                  setFormError({ ...formError, destination: '' }); // Reset error state
                }
              }}
              sx={{
                '& .MuiInputLabel-root': {
                  color: formError.destination ? '#FF6E6E' : 'inherit'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: formError.destination
                      ? '#FF6E6E'
                      : '#var(-gray-300)'
                  },
                  '&:hover fieldset': {
                    borderColor: formError.destination
                      ? '#FF6E6E'
                      : '#var(-gray-500)'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: formError.destination
                      ? '#FF6E6E'
                      : '#var(-gray-300)'
                  }
                }
              }}
            />
            {formError.destination && (
              <span
                style={{
                  color: '#FF6E6E',
                  fontSize: '14px',
                  display: 'flex',
                  marginTop: '8px'
                }}
              >
                {formError.destination}
              </span>
            )}
          </div>
          {/*
            TODO: The outline field will be hidden until the AI functinality is fixed
          */}
          <div className='outline-container' style={{ display: 'none' }}>
            <XGTextarea
              id='trip-outline-textarea'
              label={t('outline')}
              value={formData.outline}
              minRows={12}
              maxRows={12}
              sx={{
                width: '100%',
                height: 'auto',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  outline: e.target.value
                }))
              }
            />
            {submitPressed && formData.outline && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress variant='determinate' value={progress} />
              </Box>
            )}
          </div>
        </Box>
      </XGModal>
    </Box>
  );
};

CreateTrip.propTypes = {
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      countryId: PropTypes.number,
      cityId: PropTypes.number,
      title: PropTypes.string
    })
  ),
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func
};

export default CreateTrip;
