import { useEffect } from 'react';

/**
 * Custom hook to handle Escape key press.
 *
 * @param {Function} onEscape - Callback function to execute on Escape key press.
 */
const useEscapeKey = (onEscape) => {
  useEffect(() => {
    /**
     * Handles the keydown event.
     *
     * @param {KeyboardEvent} event - The keyboard event.
     */
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onEscape();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEscape]);
};

export default useEscapeKey;
